.boxSubmenu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: rgba(255, 255, 255, 0.55);

  p,
  a {
    padding: 1rem;
  }

  .headerLogo {
    width: 45px;
    border-radius: 8px;

    @media (max-width: 700px) {
      display: none;
    }
  }
}

.boxSubmenu a > img {
  width: 25px;
  margin-right: 10px;
}

.boxSubmenu p {
  margin: 0;
}

.boxWhats {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: rgb(33, 37, 41);
}

.boxWhats:hover {
  color: #0d6efd;
}
