.productsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 15px;
  gap: 15px;
  background-color: rgb(33, 37, 41);
  padding: 1rem;
}

.products {
  text-align: center;
  padding: 3rem 0;
  border: solid 1px rgba(255, 255, 255, 0.55);
  height: 400px;
  overflow: hidden;
  h2 {
    color: rgba(255, 255, 255, 0.55);
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }

  img {
    width: auto !important;
    max-width: 100%;
    height: 200px;
    padding: 1rem;
  }

  .boxItem {
    color: rgba(255, 255, 255, 0.55);
  }
}
