.commentCarousel {
  background-color: rgba(255, 255, 255, 0.55);
  color: rgb(33, 37, 41);
  padding: 2rem;
  margin: 2rem 0;
}

.carouselSlide {
  margin-bottom: 1rem;

  h3 {
    margin-bottom: 10px;
  }
}
