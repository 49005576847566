.container {
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.boxWhats {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #000;

  img {
    width: 55px;
  }
}

.boxWhats:hover {
  color: #0d6efd;
}
