.footer {
  background-color: rgb(33, 37, 41);
  color: rgba(255, 255, 255, 0.55);
  min-height: 30vh;
  padding: 2rem;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  section {
    margin: 2rem;
    width: 150px;
  }
}

.contactInfo {
  font-weight: bold;

  p {
    &:nth-child(3) {
      color: #fff;
      font-size: 12px;
    }
  }
}

.boxSocial {
  display: flex;
  justify-content: space-between;
  width: 70px;
}

.logoFooter {
  border-radius: 8px;
}
